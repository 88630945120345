<style lang="scss" scoped>
.ui-left {
  width: 360px;
  position: absolute;
  top: 109px;
  left: 20px;
  z-index: 4;
  img {
    width: 100%;
  }
}
.ui-right {
  width: 360px;
  position: absolute;
  top: 109px;
  right: 20px;
  z-index: 4;
  img {
    width: 100%;
  }
}
.b1 {
  top: 420px;
  right: 60px;
}

.b2 {
  top: 455px;
  right: 60px;
}

.b3 {
  top: 490px;
  right: 60px;
}
</style>

<template>
  <div class="index">
    <div class="ui-left" v-rc:left>
      <div class="nb b1" @click="show1"></div>
      <div class="nb b2" @click="show('停车')"></div>
      <div class="nb b3" @click="show('无车')"></div>
      <img src="./ui-left.png" alt="" />
    </div>
    <div class="ui-right" v-rc:right>
      <img src="./ui-right.png" alt="" />
    </div>
  </div>
</template>

<script>
import bus from "@/lib/bus";
export default {
  created(){
            bus.$emit('isShowCar', true)
    this.$store.state.isInner="parking"
  },
  methods: {
    show1() {
      bus.$emit("sceneRunning2", true);
      bus.$emit("closeAllSprite");
      bus.$emit("isShowCar", true);
      this.$store.state.isInner = "parking";
    },
    show(name) {
      bus.$emit("sceneRunning2", true);

      bus.$emit("closeAllSprite");

      bus.$emit("isShowCar", false);
      this.$store.state.isInner = "parking";
      bus.$emit("showCarState", name, true);
    },
  },
};
</script>
